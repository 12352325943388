import React, { useEffect,useRef } from "react";
import {
  Input,
  Radio,
  Button,
  Popover,
  DatePicker,
  Space,
  message,
  Form,
  Popconfirm,
  Select,
  TreeSelect,
  notification,
} from "antd";
import { useState } from "react";
import "./ModalContent.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpecialization,
  getCategory,
  searchUsers,
} from "../../actions/topic";
import moment from "moment";
import SelectBox from "react-select";
import Compressor from "compressorjs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const ModalContent = (props) => {
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [formSubmit, setFormSubmit] = useState(true);
  const [state, setState] = useState({});
  const { specList, catList } = useSelector((state) => state.topic);
  const [err, setErrors] = useState({});
  const [descLength, setDescLength] = useState();
  const [titleLength, setTitleLength] = useState();
  const [imageIds, setImageIds] = useState([]);
  const [data, setData] = useState([]);
  const [imgFile,setImgFile] = useState(null)

  // const options = data.map((d) => <Option key={d.email}>{d.email}</Option>);
  const options = data
  .filter((d) => d?.email) // Filter out items where email is null or undefined
  .map((d) => <Option key={d.email}>{d.email}</Option>);
  const hiddenFileInputAdrs = useRef(null);
  useEffect(() => {
    setTitleLength('');
    setDescLength('');
    setErrors({});
    setImageIds([]);
    dispatch(getSpecialization());
    dispatch(getCategory());
    if (props.editData !== null) {
      setState({...props.editData,deliverytype:props?.editData?.deliverytype == null?"noUrl":props?.editData?.deliverytype});
    } else {
      setState({ topic_subspec: [], imageFormData: [] });
    }
    if (props.drawerType === "edit") {
      setLoading(true);
    } else if (props.drawerType === "add") {
      setLoading(false);
    }
    if (props.drawerType === "edit"  && props.editData.old_image.length === 0) {
      setLoading(false);
    }
    if (props.drawerType === "edit"  && props.editData.old_pdf_image.length === 1) {
      setLoading(true);
    }
  }, [props.editData]);
  const content =
    state.format === "1" ? (
      <div>
        <p>
          Upload PDF Front and PDF Back.Title and description won't be seen in
          mobile app.
        </p>
      </div>
    ) : state.format === "2" ? (
      <div>
        <p>Add image, PDF or External video URL</p>
      </div>
    ) : (
      <div>
        <p>Add Video URL and PDF/External Url in detail page</p>
      </div>
    );

  const treeData = [];
  specList &&
    specList.data &&
    specList.data.map((specitem, index) => {
      const children = [];
      if (specitem.specialization_id) {
        specitem.specialization_id.map((subspecItem, index) => {
          children.push({
            title: subspecItem.name,
            value: `${subspecItem.name}_${subspecItem.id}`,
            key: `${subspecItem.name}_${subspecItem.id}`,
          });
          // key: `${subspecItem.name}_${subspecKey}${subspecItem.id}`
        });
      }
      treeData.push({
        title: specitem.name,
        value: `${specitem.name}_${specitem.id}`,
        key: `${specitem.name}_${specitem.id}`,
        children,
      });
      // key: `${specitem.name}_${specKey}${specitem.id}`
    });

  const category = [];
  catList &&
    catList.data &&
    catList.data.map((item) => {
      return category.push({ value: item.id, label: item.title });
    });

    function convertS3ToCloudfront(s3Url) {
      // CloudFront base URL
      const cloudfrontBaseUrl = "https://d1pt6355nydion.cloudfront.net";
      
      // Check if the input URL contains the S3 bucket pattern 
      if (s3Url && (s3Url.includes("s3.ap-south-1.amazonaws.com/s3-clinictopics")||s3Url.includes("s3.ap-south-1.amazonaws.com//s3-clinictopics"))) {
          // Extract the path after ".com"
          const path = s3Url.split("s3-clinictopics")[1];  // Get the "/videos/..." part
          // Return the CloudFront URL by appending the path
          return cloudfrontBaseUrl + path;
      } 
      return s3Url
  }
  function convertCloudfrontToS3(cloudUrl) {
    // CloudFront base URL
    const s3BaseUrl = "https://s3.ap-south-1.amazonaws.com/s3-clinictopics";
    
    // Check if the input URL contains the S3 bucket pattern 
    if (cloudUrl && cloudUrl.includes("d1pt6355nydion.cloudfront.net")) {
        // Extract the path after ".com"
        const path = cloudUrl.split("d1pt6355nydion.cloudfront.net")[1];  // Get the "/videos/..." part
        // Return the CloudFront URL by appending the path
        return s3BaseUrl + path;
    } 
    return cloudUrl
}

  const handleChange = (e) => {

    let input = e.target.value.replace(/(\r\n|\n|\r)/gm, " ");
    if(e.target.name == "video_url"){
      setState({ ...state, [e.target.name]: convertCloudfrontToS3(e.target.value) });
    }else{
      setState({ ...state, [e.target.name]: input });
    }
    setErrors({})
    // const maxLength = 150 - e.target.value.length;
    const errors = { ...err };
    if (e.target.name === "description1") {
      let maxLength = 150 - e.target.value.length;
      errors["description1"] =
        maxLength <= 0
          ? maxLength == 0? 'Limit Reached: You can only enter up to 150 characters.': `Limit reached you can only enter 150 characters. Exceeded by ${Math.abs(maxLength)} characters.`
          : `Remaining ${maxLength} characters`;
      setDescLength({ errors });
    } else if (e.target.name === "description2") {
      let maxLength = 400 - e.target.value.length;
      errors["description2"] =
        maxLength <= 0
          ? maxLength == 0? 'Limit Reached: You can only enter up to 400 characters.': `Limit Reached: You can only enter up to 400 characters. Exceeded by ${Math.abs(maxLength)} characters.`
          : `Remaining ${maxLength} characters`;
      setDescLength({ errors });
    } else if (e.target.name === "description3") {
      let maxLength = 400 - e.target.value.length;
      errors["description3"] =
        maxLength <= 0
          ? maxLength == 0? 'Limit Reached: You can only enter up to 400 characters.':`Limit Reached: You can only enter up to 400 characters. Exceeded by ${Math.abs(maxLength)} characters.`
          : `Remaining ${maxLength} characters`;
      setDescLength({ errors });
    } else if (e.target.name === "title1" || e.target.name === "title2" || e.target.name === "title3") {
      let maxLength = e.target.maxLength - e.target.value.length;
      //errors["title2"] =
        errors[e.target.name] =
        maxLength <= 0
          ? "Limit Reached"
          : `Remaining ${maxLength} characters`;
      setTitleLength({ errors });
  }
  };

  const handleCategoryChange = (value) => {
    setState({ ...state, category_data: value, category_id: value.value });
  };

  
  const handleMultipleFile = (e) => {

    const errors = { ...err };
    // for (var i = 0, numFiles = 1; i < numFiles; i++) {
    const file = e.target?.files[0];
    setImgFile(file)
    if (file?.size > 204800) {
     errors["multi_image"] = "Image size must be less than 200kb.";
     setErrors({ errors });
     setLoading(false);
    //  setFormSubmit(false);
   }
    else if (!file?.name?.match(/\.(jpg|jpeg|png)$/)) {
      errors["multi_image"] = "Please select valid image.";
      setErrors({ errors });
      setLoading(false);
      setFormSubmit(false);
    } else {
      setErrors({});
      setFormSubmit(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        file.url = reader.result;
        if (!state.topic_image) {
          setState({ ...state, topic_image: [reader.result] });
          if (!state.imageFormData) {
            setState({
              ...state,
              topic_image: [reader.result],
              imageFormData: [file],
            });
          } else {
            setState({
              ...state,
              topic_image: [reader.result],
              imageFormData: [...state.imageFormData, file],
            });
          }
        } else {
          setState({
            ...state,
            topic_image: [...state.topic_image, reader.result],
          });
          if (!state.imageFormData) {
            setState({
              ...state,
              topic_image: [...state.topic_image, reader.result],
              imageFormData: [file],
            });
          } else {
            setState({
              ...state,
              topic_image: [...state.topic_image, reader.result],
              imageFormData: [...state.imageFormData, file],
            });
          }
        }
      });
      reader.readAsDataURL(e.target.files[0]);
      if (props.drawerType === "add" && e.target.files.length === 1) {
        setLoading(true);
        let notice = "You are allowed to insert only 1 image.";
        openNotification(notice);
      } else if (props.drawerType === "edit" && e.target.files.length === 1) {
        setLoading(true);
        let notice = "You are allowed to insert only 1 image.";
        openNotification(notice);
      }
    }
  };

  const openNotification = (notice) => {
    notification.info({
      description: notice,
    });
  };

  const handleFileChange = (e) => {
    setState({ ...state, pdfUrl: e.target.files[0] });
    const pdfFile = e.target.files[0];
    let errors = { ...err };
    if (pdfFile.name.match(/\.(pdf)$/) == null) {
      errors["pdf"] = "Please select valid pdf";
      setErrors({ errors });
      setFormSubmit(false);
    } else {
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleFileChangeBack = (e) => {
    setState({ ...state, pdfUrlBack: e.target.files[0] });
    const pdfBackFile = e.target.files[0];
    let errors = { ...err };
    if (pdfBackFile.name.match(/\.(pdf)$/) == null) {
      errors["pdfsecond"] = "Please select valid pdf";
      setErrors({ errors });
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleFileChangeSecond = (e) => {
    setState({ ...state, pdfUrlSecond: e.target.files[0] });
    const pdfSecondFile = e.target.files[0];
    let errors = { ...err };
    if (pdfSecondFile.name.match(/\.(pdf)$/) == null) {
      errors["pdf2"] = "Please select valid pdf";
      setErrors({ errors });
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleFileChangeThird = (e) => {
    setState({ ...state, pdfUrlThird: e.target.files[0] });
    const pdfThirdFile = e.target.files[0];
    let errors = { ...err };
    if (pdfThirdFile.name.match(/\.(pdf)$/) == null) {
      errors["pdf3"] = "Please select valid pdf";
      setErrors({ errors });
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => {});
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onOk = (value) => {};

  const radioOnChange = (val, e) => {
    if (val === "publishtype") {
      const crntDateTime = new Date().toISOString();
      setState({
        ...state,
        publishtype: e.target.value,
        publishingtime: e.target.value === "now" ? crntDateTime : crntDateTime,
        published: e.target.value === "now" ? "1" : "0",
      });
    } else if (val === "delivery") {
      setState({ ...state, deliverytype: e.target.value });
    } else if (val === "media") {
      setState({ ...state, video_type: e.target.value });
    } else if (val === "format") {
      setState({ ...state, format: e.target.value });
    } else if (val === "deliverytype") {
      setState({ ...state, deliverytype: e.target.value });
    }
  };

  const { RangePicker } = DatePicker;

  const onChange = (value, dateString) => {
    if(dateString){
      const laterTime = new Date(dateString).toISOString();
      setState({ ...state, publishingtime: laterTime });
    }
  };

  const handleValidation = () => {
    let fields = state;
    let errors = {};
    let formIsValid = true;
    if (fields["topic_subspec"].length <= 0) {
      formIsValid = false;
      errors["topic_subspec"] = "Specialization cannot be empty";
    }
    if (!fields["category_id"]) {
      formIsValid = false;
      errors["category_id"] = "Category cannot be empty";
    }
    if (!fields["publishtype"]) {
      formIsValid = false;
      errors["publishtype"] = "Publish time cannot be empty";
    }

    if (state.format === "1") {
      if (!fields["title1"]) {
        formIsValid = false;
        errors["title1"] = " Title cannot be empty";
      }
      if (!fields["description1"]) {
        formIsValid = false;
        errors["description1"] = "Description cannot be empty";
      }
      if (props.drawerType === "add" && fields["imageFormData"].length <= 0) {
        formIsValid = false;
        errors["pdf_image"] = "Image cannot be empty";
      }
      if (props.drawerType === "edit" && fields["imageFormData"].length <= 0) {
        formIsValid = false;
        errors["pdf_image"] = "Image cannot be empty";
      }
      
      // if (props.drawerType === "add" && fields["pdfUrl"] === undefined) {
      //   formIsValid = false;
      //   errors["pdf"] = "Please upload Front Pdf";
      // }
      if (props.drawerType === "add" && fields["pdfUrlBack"] === undefined) {
        formIsValid = false;
        errors["pdfsecond"] = "Please upload Back Pdf";
      }
      if (
        state.deliverytype === "pdf" &&
        fields["pdfUrlSecond"] === undefined
      ) {
        formIsValid = false;
        errors["pdf2"] = "PDF cannot be empty";
      }
      if (fields["pdfUrl"] && fields["pdfUrl"].name.match(/\.(pdf)$/) == null) {
        formIsValid = false;
        errors["pdf"] = "Please select valid pdf";
      }
      if (
        fields["pdfUrlBack"] &&
        fields["pdfUrlBack"].name.match(/\.(pdf)$/) == null
      ) {
        formIsValid = false;
        errors["pdfsecond"] = "Please select valid pdf";
      }
    }
    if (state.format === "2") {
      if (!fields["title2"]) {
        formIsValid = false;
        errors["title2"] = " Title cannot be empty";
      }
      if (!fields["description2"] || fields["description2"]?.length>400|| fields["description2"]?.replace(/<[^>]*>/g, '').trimStart()?.length<=0) {
        formIsValid = false;
        errors["description2"] = fields["description2"]?.length>400?
        descLength && descLength.errors && descLength.errors.description2:" Description cannot be empty";   
        // console.log(errors["description2"],"errors=======>")
         }
      if (props.drawerType === "add" && fields["imageFormData"].length <= 0) {
       fields["imageFormData"]=""
        formIsValid = false;
        if(document.getElementsByClassName("ant-input").multi_image?.value?.length <=0 ){
          errors["multi_image"] = "image field cannot be empty"
        }else{
          errors["multi_image"] = "Image size cannot exceed 200kb";
        }
       
       
      }
      if (
        props.drawerType === "edit" &&
        fields["old_image"].length <= 0 &&
        fields["imageFormData"].length <= 0
      ) {
        formIsValid = false;
        errors["multi_image"] = "Image cannot be empty";
      }
      if (!fields["deliverytype"]) {
        formIsValid = false;
        errors["deliverytype"] = "Detail page cannot be empty";
      }
      if (state.deliverytype === "external" && !fields["external_url2"]) {
        formIsValid = false;
        errors["external_url2"] = "External url cannot be empty";
      }
      if (
        state.deliverytype === "pdf" &&
        fields["pdfUrlSecond"] === undefined
      ) {
        formIsValid = false;
        errors["pdf2"] = "PDF cannot be empty";
      }
      if (state.deliverytype === "external" && fields["external_url2"]) {
        var myUrl = fields.external_url2;
        var res = myUrl.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (res === null) {
          formIsValid = false;
          errors["external_url2"] = "Enter a valid URL";
        }
      }
    }
    if (state.format === "3") {
      if (!fields["title3"]) {
        formIsValid = false;
        errors["title3"] = " Title cannot be empty";
      }
      if (!fields["description3"] || fields["description3"]?.length>400|| fields["description3"]?.replace(/<[^>]*>/g, '').trimStart()?.length<=0) {
        formIsValid = false;
        errors["description3"] = fields["description3"]?.length>400?
        descLength && descLength.errors && descLength.errors.description3:" Description cannot be empty";
      }
      if (!fields["video_url"]) {
        formIsValid = false;
        errors["video_url"] = "Video url cannot be empty";
      }
      if (!fields["deliverytype"]) {
        formIsValid = false;
        errors["deliverytype"] = "Detail page cannot be empty";
      }
      if (fields["video_url"]) {
        var myUrl = fields.video_url;
        var res = myUrl.match(
         /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)|(?:https?:\/\/s3\.ap-south-1\.amazonaws\.com\/{1,2}(?:clinictopics-s3|s3-clinictopics)\/videos\/[a-zA-Z0-9-]+\.(?:mp4|mov|avi|mkv|flv|wmv|webm))/g
      );
        if (res === null) {
          formIsValid = false;
          errors["video_url"] = "Enter a valid URL";
        }
      }
      if (state.deliverytype === "external" && !fields["external_url3"]) {
        formIsValid = false;
        errors["external_url3"] = " External url cannot be empty";
      }
      if (state.deliverytype === "pdf" && fields["pdfUrlThird"] === undefined) {
        formIsValid = false;
        errors["pdf3"] = " PDF cannot be empty";
      }
      if (state.deliverytype === "external" && fields["external_url3"]) {
        var myUrl = fields.external_url3;
        var res = myUrl.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (res === null) {
          formIsValid = false;
          errors["external_url3"] = "Enter a valid URL";
        }
      }
    }

    setErrors({ errors });
    return formIsValid;
  };

  const handleSubmit = (e) => {
    // console.log(state.pdfUrlBack,"state.pdfUrlBack");
    if (handleValidation() && formSubmit) {
      let form_data = null;
      if (
        state.format !== "2" &&
        state.format !== "3" &&
        state.pdfUrl &&
        state.pdfUrl.name
      ) {
        form_data = new FormData();
        form_data.append("pdf", state.pdfUrl, state.pdfUrl.name);
      }
      let form_data_back = null;
      if (
        state.format !== "2" &&
        state.format !== "3" &&
        state.pdfUrlBack &&
        state.pdfUrlBack.name
      ) {
        form_data_back = new FormData();
        form_data_back.append(
          "pdfsecond",
          state.pdfUrlBack,
          state.pdfUrlBack.name
        );
      }
      let form_data2 = null;
      if (
        state.format !== "1" &&
        state.pdfUrlSecond &&
        state.pdfUrlSecond.name
      ) {
        form_data2 = new FormData();
        form_data2.append("pdf", state.pdfUrlSecond, state.pdfUrlSecond.name);
      }
      let form_data3 = null;
      if (state.format !== "1" && state.pdfUrlThird && state.pdfUrlThird.name) {
        form_data3 = new FormData();
        form_data3.append("pdf", state.pdfUrlThird, state.pdfUrlThird.name);
      }
      let image_data = null;
      if (
        // state.format !== "1" &&
        state.format !== "3" &&
        state.imageFormData &&
        state.imageFormData.length > 0
      ) {
        image_data = new FormData();
        state.imageFormData.forEach((file, key) => {
          if (file.size > 300000) {
            new Compressor(file, {
              quality: 0.6,
              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                // The third parameter is required for server
                image_data.append("image", result, result.name);
                // Send the compressed image file to server with XMLHttpRequest.
              },
              error(err) {},
            });
          } else {
            image_data.append("image", file, file.name);
          }
        });
      }
      let newData = state;
      delete newData["sl_no"];
      delete newData["category_title"];
      delete newData["id"];
      delete newData["topic_image"];
      delete newData["pdf"];
      delete newData["category_data"];
      delete newData["topic_val"];
      if (newData.format === "1") {
        newData["external_url"] && delete newData["external_url"];
        newData["video_url"] && delete newData["video_url"];
        newData["title"] = newData["title1"];
        newData["description"] = newData["description1"];
        newData["deliveryType"] = "pdf";
        newData["title1"] && delete newData["title1"];
        newData["description1"] && delete newData["description1"];
      } else if (newData.format === "2") {
        newData["video_url"] && delete newData["video_url"];
        newData["title"] = newData["title2"];
        newData["external_url"] = newData["external_url2"];
        newData["description"] = newData["description2"];
        newData["title2"] && delete newData["title2"];
        newData["description2"] && delete newData["description2"];
        newData["deliverytype"] === "pdf"
          ? (newData["external_url"] = "")
          : (form_data2 = null);
      } else if (newData.format === "3") {
        newData["title"] = newData["title3"];
        newData["description"] = newData["description3"];
        newData["external_url"] = newData["external_url3"];
        newData["deliveryType"] = newData["deliveryType"];
        newData["deliverytype"] = newData["deliverytype"] =="noUrl"?null:newData["deliverytype"];
        newData["title3"] && delete newData["title3"];
        newData["description3"] && delete newData["description3"];
      }
      setState({});
      setLoading(false);
      props.onFormSubmit(
        newData,
        form_data,
        form_data_back,
        form_data2,
        form_data3,
        image_data,
        imageIds
      );
    }
  };

  const cancel = (e) => {
    message.error("Cancelled");
  };

  const deleteImage = (id, image) => {
    setLoading(false);
    if (id !== null) {
      const oldPdfImages = state.old_pdf_image;
      const oldImages = state.old_image;
      const oldImageList = oldImages.filter((item) => {
        return item.id !== id;
      });
      const oldPdfImageList = oldPdfImages.filter((item) => {
        return item.id !== id;
      });
      setImageIds((oldArray) => [...oldArray, id]);
      setState({ ...state, old_image: oldImageList, old_pdf_image: oldPdfImageList });
    } else {
      const newImages = state.topic_image;
      const imageFormData = state.imageFormData;
      const newImageList = newImages.filter((item) => {
        return item !== image;
      });
      const imageFormDataList = imageFormData.filter((item) => {
        return item.url !== image;
      });
      setState({
        ...state,
        topic_image: newImageList,
        imageFormData: imageFormDataList,
      });
    }
  };

  const onSpecChange = (value) => {
    const topic = [];
    value &&
      value.map((item) => {
        let str;
        str = item.split("_", 2);
        topic.push({ subspec_id: str[1] });
      });
    setState({ ...state, topic_subspec: topic, topic_val: value });
  };

  const handleSearch = (value) => {
    if (value) {
      dispatch(searchUsers(value)).then((res) => {
        if (res.data) setData(res.data.data);
      });
    } else {
      setData([]);
    }
  };

  const handleSearchChange = (value) => {
    setState({ ...state, email: value, username: value });
  };
  const tProps = {
    treeData,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: "Please select",
    style: {
      width: "100%",
    },
    showCheckedStrategy: TreeSelect.SHOW_CHILD,
  };

  // console.log(state)

  return (
    <div>
      <Form
        name="basic"
        className="topicForm"
        labelCol={{ span: 4 }}
        labelAlign="left"
        wrapperCol={{ span: 18 }}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
      >
        <div className="modalStyle">
          <Form.Item label="Specializations">
            <TreeSelect
              value={state.topic_val}
              onChange={onSpecChange}
              autoClearSearchValue={true}
              {...tProps}
            />
            <div className="errorMsg">
              {err && err.errors && err.errors.topic_subspec}
            </div>
          </Form.Item>
          <Form.Item label="Category">
            <SelectBox
              isMulti={false}
              isSearchable={true}
              maxMenuHeight={130}
              value={state.category_data || ""}
              onChange={handleCategoryChange}
              options={category}
            />
            <div className="errorMsg">
              {err && err.errors && err.errors.category_id}
            </div>
          </Form.Item>
          <Form.Item label="Author">
            <Select
              showSearch
              value={state.username || null}
              placeholder="Search users"
              style={{ width: "100%" }}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleSearchChange}
              notFoundContent={null}
            >
              {options}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
            <Radio.Group
              onChange={(e) => radioOnChange("format", e)}
              value={state.format}
            >
              <Popover placement="top" content={content} trigger="click">
                <Radio value="1">Format 1</Radio>
              </Popover>
              <Popover placement="top" content={content} trigger="click">
                <Radio value="2">Format 2</Radio>
              </Popover>
              <Popover placement="top" content={content} trigger="click">
                <Radio value="3">Format 3</Radio>
              </Popover>
            </Radio.Group>
          </Form.Item>
          {state.format === "1" ? (
            <>
              <Form.Item label="Title">
                <div className="inputStyle">
                  <Input
                  className="kokok"
                    name="title1"
                    maxLength="60"
                    type="text"
                    onChange={handleChange}
                    value={state.title1}
                  />
                </div>
                {titleLength ? (
                  <div>
                    {titleLength &&
                      titleLength.errors &&
                      titleLength.errors.title1}
                  </div>
                ) : (
                  <div className="errorMsg">
                    {err && err.errors && err.errors.title1}
                  </div>
                )}
              </Form.Item>
              <Form.Item label="Description" wrapperCol={{ span: 18 }}>
                <div className="inputStyle" style={{marginLeft:"5px",marginBottom:"70px", width:'100%'}}>
                  {/* <TextArea
                    name="description1"
                    maxLength="150"
                    rows={4}
                    wrapperCol={{ span: 7 }}
                    onChange={handleChange}
                    value={state.description1}
                    onKeyPress={e => {
                      if(e.key === 'Enter')
                         e.preventDefault()
                      }}
                  /> */}
                  {/* <div className="text-editor"> */}
                    <ReactQuill theme="snow"
                    style={{height:"200px"}}
                                modules={{
                                  toolbar: [
                                    [{ 'header': [1, 2, false] }],
                                    ['bold', 'italic', 'underline','link'],
                                    [{'list': 'ordered'}, {'list': 'bullet'}],
                                    ['clean']
                                  ],
                                }}
                                value={state.description1} onChange={(e)=>handleChange({target:{name:"description1",value:e}})}
                                formats={[
                                  'header',
                                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                                  'list', 'bullet', 'indent',
                                  'link', 'image'
                                ]}>
                    </ReactQuill>
                  {/* </div> */}
                  {/* <ReactQuill  style={{height:300}} maxLength="150" theme="snow"  value={state.description1} onChange={handleChange} /> */}
                </div>
                {descLength ? (
                  <div>
                    {descLength &&
                      descLength.errors &&
                      descLength.errors.description1}
                  </div>
                ) : (
                  <div className="errorMsg">
                    {err && err.errors && err.errors.description1}
                  </div>
                )}
              </Form.Item>
            </>
          ) : null}
          {state.format === "2" ? (
            <>
              <Form.Item label="Title" wrapperCol={{ span: 18 }}>
                <div className="inputStyle" style={{width:'100%'}}>
                  <Input
                    name="title2"
                    maxLength="60"
                    ype="text"
                    onChange={handleChange}
                    value={state.title2}
                  />
                </div>
                {titleLength ? (
                  <div>
                    {titleLength &&
                      titleLength.errors &&
                      titleLength.errors.title2}
                  </div>
                ) : (
                  <div className="errorMsg">
                    {err && err.errors && err.errors.title2}
                  </div>
                )}
              </Form.Item>
              <Form.Item label="Description" wrapperCol={{ span: 18 }}>
                <div className="inputStyle" style={{marginLeft:"5px",marginBottom:"70px",width:'100%'}}>
                  {/* <TextArea
                    name="description2"
                    id="description2"
                    maxLength="500"
                    rows={6}
                    wrapperCol={{ span: 7 }}
                    onChange={handleChange}
                    value={state.description2}
                    onKeyPress={e => {
                      if(e.key === 'Enter')
                         e.preventDefault()
                      }}
                  /> */}
                   <ReactQuill theme="snow"
                    style={{height:"200px"}}
                                modules={{
                                  toolbar: [
                                    [{ 'header': [1, 2, false] }],
                                    ['bold', 'italic', 'underline','link'],
                                    [{'list': 'ordered'}, {'list': 'bullet'}],
                                    ['clean']
                                  ],
                                }}
                                value={state.description2} onChange={(e)=>handleChange({target:{name:"description2",value:e}})}
                                formats={[
                                  'header',
                                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                                  'list', 'bullet', 'indent',
                                  'link', 'image'
                                ]}>
                    </ReactQuill>
                </div>
                {descLength ? (
                   (err && err.errors && err.errors.description2)?
                   <div className="errorMsg">
                     {err && err.errors && err.errors.description2}
                   </div>:
                  <div>
                    {descLength &&
                      descLength.errors &&
                      descLength.errors.description2}
                  </div>
                ) : (
                  <div className="errorMsg">
                    {err && err.errors && err.errors.description2}
                  </div>
                )}
              </Form.Item>
            </>
          ) : null}
          {state.format === "3" ? (
            <>
              <Form.Item label="Title" wrapperCol={{ span: 18 }}>
                <div className="inputStyle" style={{width:'100%'}}>
                  <Input
                    name="title3"
                    maxLength="60"
                    type="text"
                    onChange={handleChange}
                    value={state.title3}
                  />
                </div>
                {titleLength ? (
                  <div>
                    {titleLength &&
                      titleLength.errors &&
                      titleLength.errors.title3}
                  </div>
                ) : (
                  <div className="errorMsg">
                    {err && err.errors && err.errors.title3}
                  </div>
                )}
              </Form.Item>
              <Form.Item label="Description" wrapperCol={{ span: 18 }}>
                <div className="inputStyle" style={{marginLeft:"5px",marginBottom:"70px",width:'100%'}}>
                  {/* <TextArea
                    name="description3"
                    maxLength="500"
                    rows={4}
                    wrapperCol={{ span: 7 }}
                    onChange={handleChange}
                    value={state.description3}
                    onKeyPress={e => {
                      if(e.key === 'Enter')
                         e.preventDefault()
                      }}
                  /> */}
                   <ReactQuill theme="snow"
                     style={{
                      // minHeight :"100px !important",
                      height:"200px",
                      // maxHeight: "300px",
                      // overflow: "hidden",
                      // overflowX:'scroll',
                      // overflowY:'scroll'
                     }}
                      modules={{
                        toolbar: [
                          [{ 'header': [1, 2, false] }],
                          ['bold', 'italic', 'underline','link'],
                          [{'list': 'ordered'}, {'list': 'bullet'}],
                          ['clean']],}}
                      value={state.description3} 
                      onChange={(e)=>handleChange({target:{name:"description3",value:e}})}
                      formats={['header','bold', 'italic', 'underline', 'strike', 'blockquote','list', 'bullet', 'indent','link', 'image']}>
                    </ReactQuill>
                </div>
                {descLength
                 ? (
                  (err && err.errors && err.errors.description3)?
                  <div className="errorMsg">
                    {err && err.errors && err.errors.description3}
                  </div>:<div>
                    {descLength &&
                      descLength.errors &&
                      descLength.errors.description3}
                  </div>
                ) : (
                  <div className="errorMsg">
                    {err && err.errors && err.errors.description3}
                  </div>
                )}{" "}
              </Form.Item>
            </>
          ) : null}
          {state.format === "2" ? (
            <Form.Item label="Image" wrapperCol={{ span: 8 }}>
              <div>
                {state.old_image &&
                  state.old_image.map((item) => (
                    <div className="img-wrap">
                      <img key={item} src={item.image} alt="" />
                      <div className="close" style={{marginLeft:"110px"}}>
                        <Popconfirm
                          title="Are you sure to delete this image ?"
                          onConfirm={() => deleteImage(item.id, item.image)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          &times;
                        </Popconfirm>
                      </div>
                    </div>
                  ))}
                {state.topic_image &&
                  state.topic_image.map((url) => (
                    <div className="img-wrap">
                      <img key={url} src={url} alt="" />
                      <div className="close" style={{marginLeft:"110px"}} >
                        <Popconfirm
                          title="Are you sure to delete this image ?"
                          onConfirm={() => deleteImage(null, url)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          &times;
                        </Popconfirm>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="inputStyle" style={{width: '315px'}}>
              <div style={{marginLeft:"50px",
                          border:'0.5px solid #B8B2B1' ,
                           padding:'6px' , display:'flex' ,
                            gap:"3px",
                            cursor: state.topic_image?.length > 0 || state?.old_image?.length > 0 ? "not-allowed" : "pointer",
                            backgroundColor: state.topic_image?.length > 0 || state?.old_image?.length > 0 ? "#f5f3f3" : "transparent",
                            color: state.topic_image?.length > 0 || state?.old_image?.length > 0 ? "#999" : "inherit",
                            }}>
                <input style={{border: state.topic_image?.length > 0 ? "#B8B2B1 solid 0.2":  "inherit",cursor: state.topic_image?.length > 0 || state?.old_image?.length > 0 ? "not-allowed" : "pointer",}} type="button" value="choose file" onClick={()=>hiddenFileInputAdrs.current.click()} disabled={state?.topic_image?.length>0 || state?.old_image?.length > 0? true : false} />
                    <span>{state?.topic_image?.length>0?imgFile?.name:""}</span>
                </div>
                {/* <Input
                  type="file"
                  name="multi_image"
                  accept="image/png, image/jpeg"
                  maxCount={1}
                  onChange={handleMultipleFile}
                  multiple
                  disabled={loading}
                /> */}
              </div>
              <div>*Supported File extensions: jpg,jpeg,png</div>
              <div className="errorMsg">
                {err && err.errors && err.errors.multi_image}
              </div>
              <div className="extraclass">
                    <input
                    type="file"
                    name="pdf_image"
                    accept="image/png, image/jpeg"
                    maxCount={1}
                    onChange={handleMultipleFile}
                    multiple
                    disabled={loading}
                    ref={hiddenFileInputAdrs}
                  />
                </div>
            </Form.Item>
          ) : null}
          {state.format === "3" ? (
            <Form.Item label="Video Url">
              <div className="inputStyle">
                <Input
                  name="video_url"
                  type="text"
                  onChange={handleChange}
                  key="desc"
                  value={convertS3ToCloudfront(state.video_url)}
                />
              </div>
              <div className="errorMsg">
                {err && err.errors && err.errors.video_url}
              </div>
            </Form.Item>
          ) : null}
          {state.format === "2" ? (
            <Form.Item label="Detail Page">
              <Radio.Group
                onChange={(e) => radioOnChange("deliverytype", e)}
                value={state.deliverytype}
              >
                <Radio value="pdf">Pdf</Radio>
                <Radio value="external">External URL</Radio>
              </Radio.Group>
              <div className="errorMsg">
                {err && err.errors && err.errors.deliverytype}
              </div>
            </Form.Item>
          ) : null}
          {state.format === "2" ? (
            <>
              {state.deliverytype === "pdf" ? (
                <>
                  {state.pdfSecond ? (
                    <a
                      href={state.pdfSecond}
                      target="_blank"
                      className="pdfStyle"
                    >
                      Click here to see the PDF
                    </a>
                  ) : null}
                  <Form.Item label="Pdf">
                    <div className="inputStyle">
                      <Input
                        type="file"
                        name="pdf2"
                        accept="image/pdf"
                        onChange={handleFileChangeSecond}
                      />
                    </div>
                    <div className="errorMsg">
                      {err && err.errors && err.errors.pdf2}
                    </div>
                  </Form.Item>
                </>
              ) : null}
              {state.deliverytype === "external" ? (
                <>
                  <Form.Item label="External URL">
                    <div className="inputStyle">
                      <Input
                        type="text"
                        name="external_url2"
                        onChange={handleChange}
                        value={state.external_url2}
                      />
                    </div>
                    <div className="errorMsg">
                      {err && err.errors && err.errors.external_url2}
                    </div>
                  </Form.Item>
                </>
              ) : null}
            </>
          ) : null}
          {state.format === "3" ? (
            <Form.Item label="Detail Page">
              <Radio.Group
                onChange={(e) => radioOnChange("deliverytype", e)}
                value={state.deliverytype}
              >
                <Radio value="pdf">Pdf</Radio>
                <Radio value="external">External URL</Radio>
                <Radio value="noUrl">No URL</Radio>
              </Radio.Group>
              <div className="errorMsg">
                {err && err.errors && err.errors.deliverytype}
              </div>
            </Form.Item>
          ) : null}
          {state.format === "3" ? (
            <>
              {state.deliverytype === "pdf" ? (
                <>
                  {state.pdfThird ? (
                    <a
                      href={state.pdfThird}
                      target="_blank"
                      className="pdfStyle"
                    >
                      Click here to see the PDF
                    </a>
                  ) : null}
                  <Form.Item label="Pdf">
                    <div className="inputStyle">
                      <Input
                        type="file"
                        name="pdf3"
                        accept="image/pdf"
                        onChange={handleFileChangeThird}
                      />
                    </div>
                    <div className="errorMsg">
                      {err && err.errors && err.errors.pdf3}
                    </div>
                  </Form.Item>
                </>
              ) : null}
              {state.deliverytype === "external" ? (
                <>
                  <Form.Item label="External URL">
                    <div className="inputStyle">
                      <Input
                        type="text"
                        name="external_url3"
                        onChange={handleChange}
                        value={state.external_url3}
                      />
                    </div>
                    <div className="errorMsg">
                      {err && err.errors && err.errors.external_url3}
                    </div>
                  </Form.Item>
                </>
              ) : null}
            </>
          ) : null}
          {state.format === "1" ? (
            <>
              {/* {state.pdfFront ? (
                <a href={state.pdfFront} target="_blank" className="pdfStyle">
                  PDF Front
                </a>
              ) : null} */}
              {/* <Form.Item label="Pdf Front">
                <div className="inputStyle">
                  <Input
                    type="file"
                    name="pdf"
                    accept="image/pdf"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="errorMsg">
                  {err && err.errors && err.errors.pdf}
                </div>
              </Form.Item> */}

              <Form.Item label="Image"wrapperCol={{ span: 8 }}>
                <div>
                {state.format === '1' && state.old_pdf_image && 
                  state.old_pdf_image.map((item) => (
                    <div className="img-wrap">
                      <img key={item} src={item.image} alt="" />
                      <div className="close" style={{marginLeft:"110px"}}>
                        <Popconfirm
                          title="Are you sure to delete this image ?"
                          onConfirm={() => deleteImage(item.id, item.image)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          &times;
                        </Popconfirm>
                      </div>
                    </div>
                  ))}
                {state.topic_image &&
                  state.topic_image.map((url) => (
                    <div className="img-wrap">
                      <img key={url} src={url} alt="" />
                      <div className="close" style={{marginLeft:"110px"}}>
                        <Popconfirm
                          title="Are you sure to delete this image ?"
                          onConfirm={() => deleteImage(null, url)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          &times;
                        </Popconfirm>
                      </div>
                    </div>
                  ))}
              </div>
               <div className="inputStyle" style={{width: '315px'}}>
                <div style={{marginLeft:"50px",
                          border:'0.5px solid #B8B2B1' ,
                           padding:'6px' , display:'flex' ,
                            gap:"3px",
                            cursor: state.topic_image?.length > 0 || state?.old_pdf_image?.length > 0 ? "not-allowed" : "pointer",
                            backgroundColor: state.topic_image?.length > 0 || state?.old_pdf_image?.length > 0 ? "#f5f3f3" : "transparent",
                            color: state.topic_image?.length > 0 || state?.old_pdf_image?.length > 0 ? "#999" : "inherit",
                            }}>
                <input style={{border: state.topic_image?.length > 0 ? "#B8B2B1 solid 0.2":  "inherit",cursor: state.topic_image?.length > 0 || state?.old_pdf_image?.length > 0 ? "not-allowed" : "pointer",}} type="button" value="choose file" onClick={()=>hiddenFileInputAdrs.current.click()} disabled={state?.topic_image?.length>0 || state?.old_pdf_image?.length > 0? true : false} />
                    <span>{state?.topic_image?.length>0?imgFile?.name:""}</span>
                </div>
                </div>
                <div>*Supported File extensions: jpg,jpeg,png</div>
                <div className="errorMsg">
                  {err && err.errors && err.errors.pdf_image || err?.errors?.multi_image}
                </div>
                <div className="extraclass">
                    <input
                    type="file"
                    name="pdf_image"
                    accept="image/png, image/jpeg"
                    maxCount={1}
                    onChange={handleMultipleFile}
                    multiple
                    disabled={loading}
                    ref={hiddenFileInputAdrs}
                  />
                </div>
              </Form.Item>

              {state.pdfBack ? (
                <a href={state.pdfBack} target="_blank" className="pdfStyle">
                  PDF Back
                </a>
              ) : null}
              <Form.Item label=" Pdf Back">
                <div className="inputStyle">
                  <Input
                    type="file"
                    name="pdfsecond"
                    accept="image/pdf"
                    onChange={handleFileChangeBack}
                  />
                </div>
                <div className="errorMsg">
                  {err && err.errors && err.errors.pdfsecond}
                </div>
              </Form.Item>
            </>
          ) : null}
          {state.published_status && state.published_status === 1 ? (
            <>
              <Form.Item label="Status" wrapperCol={{ offset: 0, span: 10 }}>
                <span className="publishedStatus">Published</span>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item label="When to Publish">
                <Radio.Group
                  onChange={(e) => radioOnChange("publishtype", e)}
                  value={state.publishtype}
                >
                  <Radio value="now">Publish Now</Radio>
                  <Radio value="later">Later</Radio>
                </Radio.Group>
                <div className="errorMsg">
                  {err && err.errors && err.errors.publishtype}
                </div>
              </Form.Item>
              {state.publishtype && state.publishtype !== "now" ? (
                <Form.Item wrapperCol={{ offset: 8, span: 14 }}>
                  <Space>
                    <DatePicker
                      showTime
                      onChange={onChange}
                      onOk={onOk}
                      defaultValue={moment(
                        state.publishingtime ? state.publishingtime : new Date()
                      )}
                    />
                  </Space>
                </Form.Item>
              ) : null}{" "}
            </>
          )}
          <Form.Item wrapperCol={{ offset: 8, span: 10 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ModalContent;
